
import { defineComponent, getCurrentInstance, ref } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import useListData from './components/useCacheListData';
import useVisble from '@/hooks/useVisible';
import {
  ajaxLoading,
  errorMessage,
  loadPage,
  successMessage,
} from '@/utils/publicMethods';
import router from '@/router';
import { httpGet, httpPost } from '@/api';
import CacheIndexForm from './components/CacheIndex/CacheIndexForm.vue';
export default defineComponent({
  name: 'CacheIndexList',
  components: { CrmList, CacheIndexForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType } = useVisble();
    const rowData = ref({});
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const rowClick = (row) => {
      loadPage('', {
        id: row.id,
        type: 1, // 1客户 2池 3回收站
      });
    };
    const setFormType = (type: number): void => {
      formType.value = type;
    };
    const closeVisble = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable();
      }
    };
    const operateRefreshFn = async (row) => {
      const res = await httpGet(
        `/malicrm/simple/putConsValueIncache?key=${row.idKey}`
      );
      if (res.code === 200) {
        successMessage('刷新成功');
        refreshTable();
      } else {
        errorMessage(res.message);
      }
    };
    // 更新单个缓存
    const updateSingleCach = async (row) => {
      const res = await httpGet(
        `/malicrm/simple/putConsValueIncache?key=${row.idKey}`
      );
      if (res.code === 200) {
        return true;
      } else {
        errorMessage(res.message);
        return false;
      }
    };
    // 初始化数据
    const getDiffKey = async () => {
      const params = {
        pageNum: 1,
        pageSize: 9999,
      };
      const res = await httpPost('/malicrm/simple/queryConsPageList', params);
      if (res.result) {
        const fiterRow = res.result.list.filter((row) => {
          return row.cacheValue !== row.value;
        });
        return fiterRow;
      } else {
        errorMessage(res.message);
      }
    };

    const getLoadPage = (url) => {
      router.push(url);
    };
    /** 一键更新缓存 */
    const updateCash = async () => {
      // 获取未更新的缓存
      const res = await getDiffKey();
      for (let i = 0; i < res.length; i++) {
        await updateSingleCach(res[i]);
      }
      successMessage('刷新成功', 2000);
      refreshTable();
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      closeVisble,
      setFormType,
      formType,
      rowClick,
      getLoadPage,
      updateCash,
      getDiffKey,
      updateSingleCach,
      operateRefreshFn,
      rowData,
    };
  },
});
