import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crm_list = _resolveComponent("crm-list")!
  const _component_CacheIndexForm = _resolveComponent("CacheIndexForm")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_crm_list, {
      url: "/malicrm/simple/queryConsPageList",
      filterOption: _ctx.filterOptions,
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "cacheIndexList",
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => [
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getLoadPage('/cache/freeTypeLabel')))
        }, " 费用管理 "),
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateCash && _ctx.updateCash(...args)))
        }, " 一键更新缓存 ")
      ]),
      _: 1
    }, 8, ["filterOption", "columOptions", "onRowClick"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible,
      erp: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CacheIndexForm, {
          formType: _ctx.formType,
          data: _ctx.rowData,
          onClose: _ctx.closeVisble
        }, null, 8, ["formType", "data", "onClose"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}