import { reactive, ref } from 'vue';
type FormData = {
  name: string | null;
  value: string | null;
  cacheValue: string | null;
  createTime: string | null;
  creator: string;
  creatorId: string;
  id: string;
  idKey: string;
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    name: null,
    value: null,
    cacheValue: null,
    createTime: null,
    creator: null,
    creatorId: null,
    id: null,
    idKey: null,
    ...params,
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const rules = reactive({
    name: [
      {
        required: true,
        message: '请输入名称',
        trigger: 'blur',
      },
    ],
    value: [
      {
        required: true,
        message: '请输入数据库值',
        trigger: 'blur',
      },
    ],
  });
  return { rules, formData, formRef, setFormData };
};
