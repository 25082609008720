import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { erpConfirm } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'idKey',
      value: '',
      placeholder: '请输入缓存key',
    },
    {
      type: 'input',
      prop: 'name',
      value: '',
      placeholder: '请输入名称',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '缓存key',
      prop: 'idKey',
      width: 240,
    },
    {
      label: '名称',
      prop: 'name',
      width: 240,
    },
    {
      label: 'redis值',
      prop: 'cacheValue',
      component: defineComponent({
        template: `
        <div class="redis-table-cell" :class="{'diff': row.cacheValue !== row.value}">
            {{row.cacheValue}}
        </div>     
        `,
        props: ['row'],
        setup() {
          return {};
        },
      }),
      width: 460,
    },
    {
      label: '数据库值',
      prop: 'value',
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
          <el-button type="primary" plain @click="refresh(row)">刷新</el-button>
          <el-button  type="warning"  plain @click="edit(row)">编辑</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 刷新 */
          const refresh = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('是否刷新缓存？')
              .then(() => {
                Instance.setupState.operateRefreshFn(row);
              })
              .catch(() => {
                return false;
              });
          };
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.showVisible();
          };
          return { refresh, edit };
        },
      }),
      prop: '',
      width: 220,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
